/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { defineMessages, useIntl } from 'react-intl-next';

import EmptyState from '@atlaskit/empty-state';
import { type LinkPickerPluginAction } from '@atlaskit/link-picker';
import { useAvailableSites } from '@atlaskit/linking-common/hooks';
import { type AvailableSite, AvailableSitesProductType } from '@atlaskit/linking-common/types';

import { EmptyStateNoResultsSvgOld } from './no-results-svg-old';
import { emptyStateWrapperStyles } from './styled';

export const messages = defineMessages({
	noResults: {
		id: 'fabric.linkPickerAtlassianPlugin.emptyStateNoResults.heading',
		defaultMessage: 'Get started with {productName}.',
		description: 'Heading message shown when there are no results, but the search form is empty',
	},
	noResultsConfluenceDescription: {
		id: 'fabric.linkPickerAtlassianPlugin.emptyStateNoResults.confluenceDescription',
		defaultMessage:
			'Recently viewed pages will appear once you <link>start using Confluence</link>.',
		description:
			'Describes possible action when there are no results for Confluence, but search form is empty',
	},
	noResultsJiraDescription: {
		id: 'fabric.linkPickerAtlassianPlugin.emptyStateNoResults.jiraDescription',
		defaultMessage: 'Recently viewed issues will appear once you <link>start using Jira</link>.',
		description:
			'Describes possible action when there are no results for Jira, but search form is empty',
	},
	noResultsDefaultDescription: {
		id: 'fabric.linkPickerAtlassianPlugin.emptyStateNoResults.defaultDescription',
		defaultMessage:
			'Recently viewed items will appear once you <link>start using {productName}</link>.',
		description:
			'Describes possible action when there are no results for a given product, but search form is empty',
	},
	noResultsConfluenceCreateOption: {
		id: 'fabric.linkPickerAtlassianPlugin.emptyStateNoResults.description',
		defaultMessage: 'You can also create a new page.',
		description: 'Describes optional call to action of creating a page',
	},
});

export const testIds = {
	emptyStateNoResults: 'link-picker-empty-state-no-results',
};

export type EmptyStateNoResultsProps = {
	product: 'confluence' | 'jira' | 'atlas' | string;
	action?: LinkPickerPluginAction;
	cloudId?: string;
	isMultisiteSearch?: boolean;
};

export const EmptyStateNoResultsOld = ({
	product,
	action,
	cloudId,
	isMultisiteSearch,
}: EmptyStateNoResultsProps) => {
	const intl = useIntl();
	const { data } = useAvailableSites();

	const isConfluence = product === 'confluence'; // confluence has some special cases

	const productDetails: ProductDetails | undefined = getProductDetails(product);
	if (!productDetails) {
		return null; // unsupported product
	}

	// find the first matching site for this product
	const firstMatchingSite = data.find(
		siteFilter(productDetails.availableSitesProduct, cloudId, isMultisiteSearch),
	);
	if (firstMatchingSite) {
		let baseUrl = firstMatchingSite.url;
		// Confluence is a special case where the baseUrl is the same as Jira's, so we need to add the path
		if (isConfluence) {
			baseUrl += '/wiki';
		}

		const intlDescription = intl.formatMessage(productDetails.description, {
			productName: productDetails.productName,
			link: (str: React.ReactNode[]) => <a href={baseUrl}>{str}</a>,
		});
		// Confluence is a special case because it has an optional "create", which we'll list as a call to action
		const intlConfCreate =
			isConfluence && action ? intl.formatMessage(messages.noResultsConfluenceCreateOption) : '';
		return (
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<div css={emptyStateWrapperStyles}>
				<EmptyState
					testId={testIds.emptyStateNoResults}
					header={intl.formatMessage(messages.noResults, {
						productName: productDetails.productName,
					})}
					description={
						<span>
							{intlDescription} {intlConfCreate}
						</span>
					}
					renderImage={() => <EmptyStateNoResultsSvgOld />}
				/>
			</div>
		);
	}

	return null;
};

interface ProductDetails {
	availableSitesProduct: AvailableSitesProductType;
	productName: string;
	description: { defaultMessage: string; description: string; id: string };
}

export const getProductDetails = (product: string): ProductDetails | undefined => {
	switch (product) {
		case 'confluence':
			return {
				availableSitesProduct: AvailableSitesProductType.CONFLUENCE,
				productName: 'Confluence',
				description: messages.noResultsConfluenceDescription,
			};
		case 'jira':
			return {
				availableSitesProduct: AvailableSitesProductType.JIRA_SOFTWARE,
				productName: 'Jira',
				description: messages.noResultsJiraDescription,
			};
		case 'atlas':
			return {
				availableSitesProduct: AvailableSitesProductType.ATLAS,
				productName: 'Atlas',
				description: messages.noResultsDefaultDescription,
			};
		default:
			return undefined;
	}
};

export const siteFilter = (
	availableSitesProduct: AvailableSitesProductType,
	cloudId: string | undefined,
	isMultisiteSearch: boolean | undefined,
): ((site: AvailableSite) => boolean) => {
	return (site: AvailableSite) => {
		// if the product doesn't match, this site doesn't match
		if (!site.products.includes(availableSitesProduct)) {
			return false;
		}
		// if the cloudId is undefined, this site matches (match everything)
		if (!cloudId) {
			return true;
		}
		// if we're mutlisite, match everything - this is probably a mistake,
		// but I'm not going to fix that here, and sticking to consistency with AtlassianLinkPickerPlugin.getRecentItems
		if (isMultisiteSearch) {
			return true;
		}
		// otherwise the site has to match the cloudId
		return site.cloudId === cloudId;
	};
};
