import React, { type ReactNode } from 'react';

import { type MessageDescriptor } from 'react-intl-next';

import Blog16Icon from '@atlaskit/icon-object/glyph/blog/16';
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16';
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16';
import Issue16Icon from '@atlaskit/icon-object/glyph/issue/16';
import Page16Icon from '@atlaskit/icon-object/glyph/page/16';
import Story16Icon from '@atlaskit/icon-object/glyph/story/16';
import Task16Icon from '@atlaskit/icon-object/glyph/task/16';
import { type LinkPickerPluginAction, type LinkSearchListItemData } from '@atlaskit/link-picker';
import { JiraIcon } from '@atlaskit/logo/jira-icon';
import { fg } from '@atlaskit/platform-feature-flags';

import { Database16Icon, Embed16Icon, Loom16Icon, Whiteboard16Icon } from './assets';
import { EmptyStateNoResults } from './empty-state-no-results';
import { messages } from './messages';

enum LinkContentType {
	'jira.issue',
	'jira.issue.bug',
	'jira.issue.story',
	'jira.issue.task',
	'jira.issue.epic',
	'jira.dashboard',
	'jira.board',
	'jira.project',
	'jira.filter',
	'confluence.page',
	'confluence.blogpost',
	'confluence.whiteboard',
	'loom.video',
	'confluence.database',
	'confluence.embed',
}

export const renderEmptyStateNoResults = (
	product: string,
	action?: LinkPickerPluginAction,
	cloudId?: string,
	isMultisiteSearch?: boolean,
): ReactNode => {
	return (
		<EmptyStateNoResults
			product={product}
			action={action}
			cloudId={cloudId}
			isMultisiteSearch={isMultisiteSearch}
		/>
	);
};

export const isLinkContentType = (
	contentType?: string,
): contentType is keyof typeof LinkContentType => {
	return Boolean(contentType && Object.keys(LinkContentType).includes(contentType));
};

export const mapContentTypeToIcon: Record<
	keyof typeof LinkContentType,
	{
		icon: React.ComponentType<{ alt: string }>;
		iconAlt: MessageDescriptor;
	}
> = {
	'jira.issue': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Issue16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconIssueLabel,
	},
	'jira.issue.bug': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Bug16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconBugLabel,
	},
	'jira.issue.story': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Story16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconStoryLabel,
	},
	'jira.issue.task': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Task16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconTaskLabel,
	},
	'jira.issue.epic': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Epic16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconEpicLabel,
	},
	'jira.dashboard': {
		icon: ({ alt }) => <JiraIcon label={alt} size="xsmall" appearance="brand" />,
		iconAlt: messages.hyperlinkIconDashboardLabel,
	},
	'jira.board': {
		icon: ({ alt }) => <JiraIcon label={alt} size="xsmall" appearance="brand" />,
		iconAlt: messages.hyperlinkIconBoardLabel,
	},
	'jira.project': {
		icon: ({ alt }) => <JiraIcon label={alt} size="xsmall" appearance="brand" />,
		iconAlt: messages.hyperlinkIconProjectLabel,
	},
	'jira.filter': {
		icon: ({ alt }) => <JiraIcon label={alt} size="xsmall" appearance="brand" />,
		iconAlt: messages.hyperlinkIconFilterLabel,
	},
	'confluence.page': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Page16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconPageLabel,
	},
	'confluence.blogpost': {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
		icon: ({ alt }) => <Blog16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconBlogLabel,
	},
	'confluence.whiteboard': {
		icon: ({ alt }) => <Whiteboard16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconWhiteboardLabel,
	},
	'loom.video': {
		icon: ({ alt }) => <Loom16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconLoomLabel,
	},
	'confluence.database': {
		icon: ({ alt }) => <Database16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconDatabaseLabel,
	},
	'confluence.embed': {
		icon: ({ alt }) => <Embed16Icon label={alt} />,
		iconAlt: messages.hyperlinkIconEmbedLabel,
	},
};

export const getIconFromContentType = (
	contentType: string,
): Pick<LinkSearchListItemData, 'icon' | 'iconAlt'> => {
	if (!isLinkContentType(contentType)) {
		return {
			// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
			icon: ({ alt }: { alt: string }) => <Page16Icon label={alt} />,
			iconAlt: messages.defaultAltText,
		};
	}

	if (contentType === 'jira.issue' && fg('confluence-issue-terminology-refresh')) {
		return {
			// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19539
			icon: ({ alt }: { alt: string }) => <Issue16Icon label={alt} />,
			iconAlt: messages.hyperlinkIconIssueLabelIssueTermRefresh,
		};
	}

	return mapContentTypeToIcon[contentType];
};

// Workaround to get alt text for images from url
// Can be removed when alt={iconAlt} will be available from GraphQL
// More details: https://a11y-internal.atlassian.net/browse/AK-811
export const getCorrectAltByIconUrl = (iconUrl: string) => {
	let alt = messages.defaultAltText;
	// TODO add in extra cases for V3 api
	if (iconUrl.includes('story.svg')) {
		alt = messages.story;
	}

	if (iconUrl.includes('epic.svg')) {
		alt = messages.epic;
	}

	if (iconUrl.includes('avatarId=10318')) {
		alt = messages.task;
	}

	if (iconUrl.includes('avatarId=10303')) {
		alt = messages.bug;
	}

	if (iconUrl.includes('avatarId=10518')) {
		alt = messages.subTask;
	}

	if (iconUrl.includes('avatarId=10310')) {
		alt = messages.improvement;
	}

	return alt;
};

export const getIconFromIconUrl = (
	iconUrl: string,
): Pick<LinkSearchListItemData, 'icon' | 'iconAlt'> => {
	return {
		icon: iconUrl,
		iconAlt: getCorrectAltByIconUrl(iconUrl),
	};
};
